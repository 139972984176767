<template>
  <v-container
    fluid
    tag="section"
  >

    <v-row justify="center">
      <v-col
        cols="12"
        md="10"
      >
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Uploader une nouvelle facture
            </div>

            <div class="subtitle-1 font-weight-light">
              Indiquez les informations
            </div>
          </template>

          <v-form
            ref="facture"
            method="post"
            @submit.prevent="validate"
          >
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    label="N° facture"
                    class="purple-input"
                    v-model="facture.numero"
                    hint="Ex. : 123"
                    type="number"
                    :rules="[v => !!v || 'Indiquez le n°']"
                    required
                    @blur="updateReference()"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="7"
                >
                  <v-autocomplete
                    label="Formation associée"
                    v-model="facture.formationAssociee"
                    :items="formations"
                    item-text="intitule"
                    item-value="id"
                    return-object
                    clearable
                    @input="updateInfos()"
                  >
                    <template
                      slot="selection"
                      slot-scope="data"
                    >
                      {{ data.item.intitule }} ({{ data.item.reference }}) du {{ new Date(data.item.dateDebut).toLocaleString().substring(0, 10) }}
                    </template>
                    <template
                      slot="item"
                      slot-scope="data"
                    >
                      {{ data.item.intitule }} ({{ data.item.reference }}) du {{ new Date(data.item.dateDebut).toLocaleString().substring(0, 10) }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    label="Référence"
                    class="purple-input"
                    v-model="facture.reference"
                    hint="Exemple : FVT_123_XX"
                    :rules="[v => !!v || 'Indiquez la référence']"
                    required
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-autocomplete
                    label="Client"
                    v-model="facture.client.id"
                    :items="clients"
                    item-text="nomCommercial"
                    item-value="id"
                    :rules="[v => !!v || 'Indiquez le client destinataire']"
                    clearable
                    required
                    @change="updateReference()"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-text-field
                    label="Intitulé"
                    v-model="facture.intitule"
                    hint="Exemple : « Formation Python avancé »"
                    :rules="[v => !!v || 'Indiquez l\'intitulé de la facture']"
                    required
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    label="N° de dossier"
                    class="purple-input"
                    v-model="facture.noDossier"
                    hint="N° de dossier ou n° de commande"
                  />
                </v-col>
              </v-row>
              <v-card-title>
                Informations sur la facture
              </v-card-title>
              <v-row>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-menu
                    ref="menuDateEmission"
                    v-model="menuDateEmission"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedDateEmissionFormatted"
                        label="Date d'émission"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        required
                      />
                    </template>
                    <v-date-picker
                      class="mt-0 create-speaker-date-picker"
                      v-model="facture.dateEmission"
                      :first-day-of-week="1"
                      locale="fr"
                      @input="menuDateEmission = false;"
                      required
                    />
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-menu
                    ref="menuDateEnvoi"
                    v-model="menuDateEnvoi"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedDateEnvoiFormatted"
                        label="Date d'envoi"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        hint="Laisser vide si pas envoyée"
                        persistent-hint
                        clearable
                      />
                    </template>
                    <v-date-picker
                      class="mt-0 create-speaker-date-picker"
                      v-model="facture.dateEnvoi"
                      :first-day-of-week="1"
                      locale="fr"
                      @input="menuDateEnvoi = false;"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-menu
                    ref="menuDatePaiement"
                    v-model="menuDatePaiement"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="computedDatePaiementFormatted"
                        label="Date de paiement"
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        hint="Laisser vide si pas payée"
                        persistent-hint
                        clearable
                      />
                    </template>
                    <v-date-picker
                      class="mt-0 create-speaker-date-picker"
                      v-model="facture.datePaiement"
                      :first-day-of-week="1"
                      locale="fr"
                      @input="menuDatePaiement = false;"
                    />
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    label="Montant HT"
                    v-model="facture.montantHT"
                    class="purple-input"
                    type="number"
                    suffix="€"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    label="Montant TVA"
                    v-model="facture.montantTVA"
                    class="purple-input"
                    type="number"
                    suffix="€"
                    :value="calculTVA()"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    label="Total TTC"
                    v-model="facture.montantTotal"
                    class="purple-input"
                    type="number"
                    suffix="€"
                    :value="calculTotalTTC()"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-file-input
                    v-model="facturePDF"
                    accept=".pdf"
                    label="Facture"
                    prepend-icon="mdi-paperclip"
                    hint="Format accepté : PDF"
                    persistent-hint
                    required
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="text-right"
                >
                  <v-btn
                    color="success"
                    class="mr-0"
                    type="submit"
                    @click="dialogSubmit = true"
                    :disabled="uploadDisabled"
                  >
                    Uploader la facture
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <template>
            <v-dialog
              v-model="dialogSubmit"
              max-width="500px"
            >
              <v-card>
                <v-card-title
                  class="headline"
                >
                  Confirmer l'upload de cette facture ?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="red darken-1"
                    @click="dialogSubmit = false"
                  >
                    Annuler
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    @click="uploadInvoice"
                  >
                    Confirmer
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {
    data() {
      return {
        clients: [],
        formations: [],
        dialogSubmit: false,
        uploadDisabled: false,
        facturePDF: null,
        facture: {
          reference: '',
          numero: '',
          client: {},
          destinataire: {},
          dateEmission: null,
          dateEnvoi: null,
          dateEcheance: null,
          datePaiement: null,
          montantHT: 0,
          montantTVA: 0,
          montantTotal: 0,
          intitule: '',
          noDossier: '',
          frais: 0.0,
          envoyee: false,
          payee: false,
          lienFacture: '',
          formationAssociee: null,
          lignesFacture: [],
        },
        menuDateEmission: false,
        menuDateEnvoi: false,
        menuDatePaiement: false,
      };
    },
    created() {
      let factures = [];

      this.axios.get('/facturesSortantes')
        .then((res) => {
          factures = [...res.data].sort((a, b) => (a.numero > b.numero) ? 1 : -1);

          if (factures.length > 0) {
            this.facture.numero = factures[factures.length - 1].numero + 1;
          }
        });

      this.axios.get('/clients')
        .then((res) => {
          this.clients = res.data;
          this.clients.sort((a, b) => a.nomCommercial.localeCompare(b.nomCommercial));
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération des clients', type: 'warn' });
        });
      this.axios.get('/formations')
        .then((res) => {
          this.formations = res.data.filter((f) => f.etat === 'ENCOURS' || f.etat === 'TERMINE').sort(function(a, b) {
            return new Date(b.dateDebut) - new Date(a.dateDebut);
          });
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération des formations', type: 'warn' });
        });
    },

    computed: {
      computedDateEmissionFormatted () {
        return this.formatDate(this.facture.dateEmission);
      },
      computedDateEnvoiFormatted () {
        return this.formatDate(this.facture.dateEnvoi);
      },
      computedDatePaiementFormatted () {
        return this.formatDate(this.facture.datePaiement);
      },
    },
    methods: {
      uploadInvoice() {
        if (this.facture.dateEnvoi) {
          this.facture.envoyee = true;
          this.facture.dateEcheance = new Date(this.facture.dateEnvoi);
          this.facture.dateEcheance.setDate(this.facture.dateEcheance.getDate() + 60);
        }
        if (this.facture.datePaiement) {
          this.facture.payee = true;
        }

        const formData = new FormData();
        formData.append('file', this.facturePDF);

        this.axios.post('/facturesSortantes', this.facture)
          .then(res => {
            this.axios.post('/facturesSortantes/' + this.facture.reference + '/uploadPDF', formData);
            return res;
          })
          .then(res => {
            this.setSnack({ text: 'La facture a bien été uploadée', type: 'success' });
            this.$router.push({ name: 'Factures', params: { year: new Date().getFullYear().toString() }, hash: '#' + parseInt(new Date().getMonth() + 1) });
            return res;
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors de l\'upload de la facture', type: 'warn' });
          })
      },

      updateReference() {
        let num = '000';
        const client = this.clients.filter((c) => c.id === this.facture.client.id)[0];
        if (this.facture.numero < 10) {
          num = '00' + this.facture.numero;
        } else if (this.facture.numero < 100) {
          num = '0' + this.facture.numero;
        } else {
          num = this.facture.numero;
        }
        this.facture.reference = 'FVT_' + num + '_' + client.codeFacture;
      },

      updateInfos() {
        if (this.facture.formationAssociee.type !== 'INTER') {
          this.facture.client = this.facture.formationAssociee.client;
        }
        this.facture.intitule = this.facture.formationAssociee.intitule;
        this.facture.noDossier = this.facture.formationAssociee.reference;
        this.facture.dateEmission = this.facture.formationAssociee.dateFin;
        this.facture.dateEnvoi = this.facture.formationAssociee.dateFin;
        this.facture.datePaiement = this.facture.formationAssociee.dateFin;
        this.updateReference();
      },

      calculTVA() {
        let tva = this.facture.montantHT * 0.2 || 0;
        tva = Math.round((tva + Number.EPSILON) * 100) / 100
        this.facture.montantTVA = tva
        return tva;
      },

      calculTotalTTC() {
        let ttc = this.facture.montantHT * 1.2 || 0;
        ttc = Math.round((ttc + Number.EPSILON) * 100) / 100
        this.facture.montantTotal = ttc
        return ttc;
      },

      validate () {
        this.$refs.facture.validate();
      },

      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },
  }

</script>
